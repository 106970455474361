<template>
    <div>
        <b-row>
            <b-col md="6">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas</span>
                    </CCardHeader>
                    <CCardBody>
                        <span class="h6 text-muted">ESTADÍSTICAS DE LOS COMENTARIOS
                        </span>
                        <hr>
                        <b-row class="justify-content-center text-center">
                            <b-col cols="12" class=" my-2 icon-custom">
                                <i style="inline-size: auto" class="ico fas fa-camera fa-3x pb-1"></i>
                                <br>
                                <span class="lg-numero">{{listComentario.length}}</span>
                                <br>
                                <span class="text-muted">Comentarios Registrados</span>
                            </b-col>
                        </b-row>
                    </CCardBody>
                </CCard>
            </b-col>
            <b-col md="12">
                <CCard>
                    <CCardHeader color="primario" text-color="white">
                        <i class="fas fa-list fa-md"></i><span class="h5"> Lista de Comentarios</span>
                        <b-button v-if="listComentario.length >= 1" class="float-right" variant="success" size="sm" @click="exportDataToExcel">
                        <i class="fas fa-download mr-1"></i>
                        Exportar</b-button>
                    </CCardHeader>
                    <CCardBody>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table bordered hover show-empty mediun responsive outlined :items="listComentario" :fields="camposComentarios" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template v-slot:cell(nroDoc)="data">
                                        {{data.item.nroDocUuser | truncate(15)}}
                                    </template>
                                    <template v-slot:cell(nombre)="data">
                                        {{data.item.nombreUser | truncate(30)}}
                                    </template>
                                    <template v-slot:cell(fechahora)="data">
                                        {{formatearFecha(data.item.fechaForm,'DD-MM-YYYY')}} - {{data.item.horaFormat}}
                                    </template>
                                    <template v-slot:cell(comentario)="data">
                                        {{data.item.comentario | truncate(15)}}
                                    </template>
                                    <template v-slot:cell(url)="data">
                                        <!-- {{data.item.urlPublicImagen}} -->
                                        <a :href="data.item.urlPublicImagen" target="_blank">{{data.item.nombreImagen}}</a> 
                                    </template>
                                    <template v-slot:cell(opciones)="param">
                                        <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActComentario(param)" variant="dark" class=" mr-1 mb-1">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-search my-0 mx-0"></i>
                                            </span>
                                        </b-button>

                                        <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarComentario(param)" variant="danger">
                                            <span class="btn-inner--icon">
                                                <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                            </span>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </CCardBody>
                    <CCardFooter>
                        <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                    </CCardfooter>
                </CCard>
            </b-col>
        </b-row>

        <CModal :show.sync="modalActComentario" size="xl" color="primario" :closeOnBackdrop="false">
            <template #header>
                <h6 class="modal-title"> <i class="fas fa-search fa-md"></i>
                    <span class="h5"> Mostrar</span></h6>
                <CButtonClose @click="modalActComentario = false" class="text-white" />
            </template>
            <validation-observer ref="observer1" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit()">
                    <b-row>
                        <b-col md="6">
                            <validation-provider name="nro de documento" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Nro de Documento:" class="mb-2">
                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Nro. de Documento" v-model.trim="datosActComentario.nroDocUuser"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre de pasajero" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Nombre del Pasajero:" class="mb-2">
                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre del Pasajero" v-model.trim="datosActComentario.nombreUser"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="3">
                            <validation-provider name="fecha" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Fecha:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosActComentario.fechaForm"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="3">
                            <validation-provider name="hora" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Hora:" class="mb-2">
                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la Hora" v-model.trim="datosActComentario.horaFormat"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="6">
                            <validation-provider name="nombre imagen" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Nombre de la Imagen:" class="mb-2">
                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el Nombre de la Imagen" v-model.trim="datosActComentario.nombreImagen"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="url de la imagen" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="URL de la Imagen:" class="mb-2">
                                    <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la URl de la Imagen" v-model.trim="datosActComentario.urlPublicImagen"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col md="12">
                            <validation-provider name="comentario" :rules="{required: false}" v-slot="validationContext">
                                <b-form-group label="Comentario:" class="mb-2">
                                    <b-form-textarea v-model="datosActComentario.comentario" :state="getValidationState(validationContext)" placeholder="Ingrese el Comentario" rows="3" max-rows="6"></b-form-textarea>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col lg="12" class="text-right my-2">
                            <!-- <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                                Actualizar
                            </b-button> -->
                            <b-button variant="danger" @click="modalActComentario = false">
                                Cancelar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </CModal>
    </div>
</template>


<script>
import firebase from 'firebase';
const dbComentarios = firebase.firestore().collection('comentario');
import XLSX from 'sheetjs-style-v2';

export default {
    data() {
        return {
            listComentario: [],

            camposComentarios: [
                {
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nroDoc",
                    label: "Nro. Documento",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Nombre Pasajero",
                    class: "text-center",
                },
                {
                    key: "fechahora",
                    label: "Fecha y Hora",
                    class: "text-center",
                },
                
                {
                    key: "comentario",
                    label: "Comentario",
                    class: "text-center",
                },
                {
                    key: "url",
                    label: "URL",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            modalActComentario: false,

            datosActComentario: {
                id: '',
                nroDocUuser: '',
                nombreUser: '',
                comentario: '',
                fechaForm: '',
                horaFormat: '',
                nombreImagen: '',
                urlPublicImagen: '',
            },
        }
    },
    methods: {
        exportDataToExcel() {
            let now = moment().format('YYYYMMDDHHmm');

            const valUpper = text => {
                if (text != null && text !== undefined) {
                    return text.toString().toUpperCase();
                }
                return '';
            };

            const valDate = date => {
                if (date && date !== undefined) {
                    return moment(date).format('DD-MM-YYYY')
                }
                return '';
            };

            let dataExcel = this.listComentario.map(data => {
                return {
                    A: valUpper(data['nroDocUuser']),
                    B: valUpper(data['nombreUser']),
                    C: valDate(data['fechaForm']),
                    D: valUpper(data['horaFormat']),
                    E: valUpper(data['comentario']),
                    F: data['nombreImagen'],
                    G: data['urlPublicImagen'],
                };
            });

            let dataWS = XLSX.utils.json_to_sheet(dataExcel, {
                origin: 'A4',
            });

            let dataWSMerges = [{
                s: {
                    r: 1,
                    c: 1,
                },
                e: {
                    r: 1,
                    c: 3,
                },
            }];

            dataWS['!merges'] = dataWSMerges;

            let dataWSCols = [
                {
                    wch: 20, //A
                },
                {
                    wch: 30, //B
                },
                {
                    wch: 15, //C
                },
                {
                    wch: 15, //D
                },
                {
                    wch: 50, //E
                },
                {
                    wch: 100, //F
                },
                {
                    wch: 150, //G
                },
            ];

            dataWS['!cols'] = dataWSCols;

            for(let i of [
                {
                    f: 'B2',
                    v: 'COMENTARIOS',
                },
                {
                    f: 'C2',
                },
                {
                    f: 'D2',
                },
                {
                    f: 'A4',
                    v: 'NRO. DOCUMENTO',
                },
                {
                    f: 'B4',
                    v: 'NOMBRE DEL PASAJERO',
                },
                {
                    f: 'C4',
                    v: 'FECHA',
                },
                {
                    f: 'D4',
                    v: 'HORA',
                },
                {
                    f: 'E4',
                    v: 'COMENTARIO',
                },
                {
                    f: 'F4',
                    v: 'NOMBRE DE LA IMAGEN',
                },
                {
                    f: 'G4',
                    v: 'URL DE LA IMAGEN',
                },
            ]){
                XLSX.utils.sheet_add_aoa(dataWS, [
                    [i.v || '']
                ], {
                    origin: i.f,
                });

                dataWS[i.f].s = {
                    numFmt: i.w || 'general',
                    fill: {
                        fgColor: {
                            rgb: i.b ? 'FFFFFF' : 'F2F2F2',
                        },
                    },
                    font: {
                        name: 'arial',
                        sz: 8,
                        bold: i.b ? false : true,
                        color: {
                            rgb: i.b ? '000000' : '1F497D',
                        },
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'A6A6A6',
                            },
                        },
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: 1,
                    },
                };
            }

            for (let k in dataExcel) {
                for (let i of Object.keys(dataExcel[0])) {
                    dataWS[`${i}${(parseInt(k) + 5).toString()}`].s = {
                        numFmt: '@',
                        fill: {
                            fgColor: {
                                rgb: 'FFFFFF',
                            },
                        },
                        font: {
                            name: 'arial',
                            sz: 8,
                            color: {
                                rgb: '000000',
                            },
                        },
                        border: {
                            top: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            bottom: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            right: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                            left: {
                                style: 'thin',
                                color: {
                                    rgb: 'A6A6A6',
                                },
                            },
                        },
                        alignment: {
                            horizontal: 'center',
                            vertical: 'center',
                        },
                    };
                }
            }

            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, 'COMENTARIOS');
            XLSX.writeFile(wb, `COMENTARIOS ${now}.xlsx`);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length;
            this.paginaActual = 1;
        },
        listarComentarios() {
            let me = this;

            dbComentarios
            .where("estado","==", 2)
            .where("idCliente", "==", String(me.$store.state.user.idCliente))
            .onSnapshot((querySnapshot) => {
                me.listComentario = [];
                querySnapshot.forEach((doc) => {
                    me.listComentario.push({
                        id: doc.id,
                        nroDocUuser: doc.data().nroDocUuser,
                        nombreUser: doc.data().nombreUser,
                        comentario: doc.data().comentario,
                        fechaCreacion: doc.data().fechaCreacion,
                        fechaForm: doc.data().fechaForm,
                        horaFormat: doc.data().horaFormat,
                        nombreImagen: doc.data().nombreImagen,
                        urlPublicImagen: doc.data().urlPublicImagen,    
                    });
                });

                me.listComentario.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
            });
        },
        formatearFecha(fecha,formato) {
            return moment(fecha).format(formato);
        },
        abrirModalActComentario(param) {
            let me = this;
            
            me.datosActComentario.id = param.item.id;
            me.datosActComentario.nroDocUuser = param.item.nroDocUuser;
            me.datosActComentario.nombreUser = param.item.nombreUser;
            me.datosActComentario.comentario = param.item.comentario;
            me.datosActComentario.fechaForm = param.item.fechaForm;
            me.datosActComentario.horaFormat = param.item.horaFormat;
            me.datosActComentario.nombreImagen = param.item.nombreImagen;
            me.datosActComentario.urlPublicImagen = param.item.urlPublicImagen;
            me.modalActComentario = true;

        },
        eliminarComentario(param) {
            let me = this;

            me.$swal.fire({
                title: '¿Estas seguro de eliminar?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbComentarios.doc(param.item.id).update({
                        estado: 1,
                        fechaEliminar: firebase.firestore.Timestamp.fromDate(new Date()),
                    })
                    .then(() => {
                        me.swat('success', 'Eliminado satisfactoriamente')
                    })
                    .catch((error) => {
                        me.swat('error', 'Algo salió mal!')
                    });

                }
            });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title,
            });
        },
        resetModalActComentario() {
            this.$nextTick(() => {
                this.$refs.observer1.reset();
            });

            this.datosActComentario.id = '';
            this.datosActComentario.nroDocUuser = '';
            this.datosActComentario.nombreUser = '';
            this.datosActComentario.comentario = '';
            this.datosActComentario.fechaForm = '';
            this.datosActComentario.horaFormat = '';
            this.datosActComentario.nombreImagen = '';
            this.datosActComentario.urlPublicImagen = '';
        },
    },
    computed: {

    },
    watch: {
        modalActComentario: function (val) {
            if (val == false) {
                this.resetModalActComentario();
            }
        },
    },
    mounted(){
        if (this.$store.state.user) {
            this.listarComentarios();
        }
    }
}
</script>